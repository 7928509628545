.background {
    background-color: rgba(20,189,240,.1);
    text-align: center;
    margin-top:50px;
    width: 100%;
}
.footerLogo {
    padding-top: 40px;
    padding-bottom: 20px;
    width: 150px;
}
.copyRight {
    color:#14BBEF;
    font-size: 13px;
}