.header{
  margin: 30px 0px;
  padding: 0px 0px 30px 0px;
  border-bottom: 1px solid gainsboro;
}
.headerLogo{
  width: 150px; 
}
.header a {
  color: black;
  padding: 0 10px;
}

.header a:hover {
  cursor: pointer;
}