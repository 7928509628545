
* {
  margin: 0;
}

.mainTitles {
  margin-top: 30px;
}

h1 {
  margin: 60px 0px 30px 0px;
}

p {
  margin-bottom: 30px;
}

button {
  background-color: #49B4AA;
  color: white;
  float: right;
  border: none;
  font-size: 16px;
  font-weight: 600;;
  margin: 30px 0px;
  padding: 8px 12px;
}

.CodeMirror {
  border: 1px solid #eee;
  min-height: calc(55vh - 60px);
}

.CodeMirror-scroll {
  min-height: calc(55vh - 60px);
}
